.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.reviewTableCell {
    min-height: 100vh;
}

.paper {
    margin: 24px 0 16px 0;
    padding: 24px;
    overflow-x: auto;
    max-height: 90vh;
}

.title {
    text-transform: uppercase;
}

.close {
    margin: -16px -16px 0 0;
    float: right;
}